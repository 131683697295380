<template>
	<div class="ct_box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="ct_title_box flex">
			<p class="ct_title">选择需要开票的订单</p>
			<div class="search_status_box flex">
				<div class="search_box flex">
					<medium_search placeholder="请输入订单号" @searchMedium="searchMedium" />
				</div>
			</div>
		</div>
		<div style="position: relative;">
			<div style="padding-bottom:60px;box-sizing: border-box;">
				<table class="table">
					<thead>
						<tr>
							<td><input type="checkbox" v-model="Allcheck" @click="checkSwitch()" /></td>
							<td>订单号</td>
							<td>金额</td>
							<td>时间</td>
							<!-- <td>备注</td> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in tableDate" :key="item.id">
							<td>
								<input type="checkbox" v-model="item.check" @click="checkFn(index)" />
							</td>
							<td>{{ item.sn }}</td>
							<td>{{ item.order_price }}</td>
							<td>{{ item.ctime }}</td>
							<!-- <td>xxxxxxxx</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			<!-- 分页器 -->
			<pagination :page="page" @pageChange="pageChange" :length_num="10" :count="count"
				:total_page="total_page" />
		</div>
		<div class="form_box">
			<div class="title_box flex">
				<p class="ct_title">填写开票信息</p>
				<button class="but_or" @click="submitFn">提交</button>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">开票主体及类型：</p>
				<div class="flex radio_box">
					<el-radio v-model="form.radio" :label="item" v-for="(item, index) in RadioType" :key="index">{{
		item.title }}</el-radio>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">选择开票抬头：</p>
				<div class="radio_box">
					<!-- {{ form.type }} -->
					<el-select @change="InvoiceLs" v-model="form.type" placeholder="请选择">
						<el-option v-for="item in Invoiceform" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
					<!-- <el-input v-model="invoiceId" placeholder="请输入内容"></el-input> -->
					<p class="remark">
						如果没有开票信息，请先
						<span style="cursor: pointer" @click.stop="$router.push('/user/AddInvoice')">添加开票资料</span>
					</p>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 20px">
				<p class="radio_title">发票项目名称：</p>
				<div class="flex radio_box">
					<el-radio v-model="form.invoiceContent" :label="1">宣传策划</el-radio>
					<!-- <el-radio v-model="form.invoiceContent" :label="2">网络推广服务费</el-radio> -->
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">开票金额：</p>
				<div class="radio_box">
					<el-input v-model="invoiceMoney" placeholder="自动识别选择的订单金额，不可填写" readonly></el-input>
				</div>
			</div>
			<div class="flex" style="align-items: center; margin-bottom: 2rem">
				<p class="radio_title">扣税点金额：</p>
				<div class="radio_box">
					<el-input v-model="deductTheTax" placeholder="自动计算，不可填写" readonly></el-input>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { checkAll } from '@/util/checkAll'
import medium_search from '@/components/medium_search'
export default {
	computed: {
		...mapState(['ApplyForInvoiceTitle']),
		invoiceMoney: function () {
			// console.log(navVal, oldVal);
			var n = 0;
			this.active_table.forEach((item, index) => {
				if (this.active_table[index].check) {
					n += parseInt(this.active_table[index].order_price);
					// if (!this.active_table.length) {
					// 	this.active_table.push(item)
					// }
				}
			})
			return n.toFixed(2);
		},
		deductTheTax: function () {
			// console.log(navVal, oldVal);
			var n = 0;
			n = this.invoiceMoney * this.form.radio.points / 100
			return n.toFixed(2);
		}
	},
	components: {
		medium_search
	},
	watch: {
		tableDate: {
			handler(newValue) {
				console.log(newValue);
				if (this.tableDate.length > 0) {
					let count = 0
					for (var i = 0; i < this.tableDate.length; i++) {
						if (this.tableDate[i].check == true) {
							count++;
						}
					}
					if (count == this.tableDate.length) {
						this.Allcheck = true
					} else {
						this.Allcheck = false
					}
					console.log('count', count);
				}
			},
			deep: true,
		}
	},
	mounted() {
		this.curlGet('/api/tax').then((res) => {
			// console.log(res);
			if (res.data.code) {
				this.RadioType = res.data.data
				this.form.radio = this.RadioType[0]
			}
		})
		let data = {}
		this.curlGet('/api/users/invoice_info').then((res) => {
			res.data.data.list.forEach((item, index) => {
				this.Invoiceform.push({
					id: item.id,
					title: item.header,
				})
			})
		})
		this.get_list()
	},
	data() {
		return {
			fullscreenLoading: false,
			RadioType: [],
			form: {
				title: '', //订单号
				type: '', //开票标题
				radio: {}, //开票标题类型
				invoiceContent: 1, //开票内容
				order_ids: '',
			},
			// invoiceMoney: '', //发票金额
			// deductTheTax: '', //扣税点数
			total_page: 0, //分页总页数
			count: 0, //总条数
			invoice: [], //开票标题列表
			Invoiceform: [],
			value: '',
			page: 1, //当前页
			Allcheck: false,
			tableDate: [],
			active_table: [],
		}
	},
	methods: {
		searchMedium(title) {
			this.form.title = title
			this.pageChange(1)
		},
		pageChange(pageVal) {
			this.page = pageVal
			this.get_list()
		},
		checkSwitch() {
			this.Allcheck = !this.Allcheck
			this.active_table = checkAll(this.Allcheck, this.tableDate, this.active_table)
			console.log(this.active_table);
		},
		checkFn(index) {
			this.tableDate[index].check = !this.tableDate[index].check
			if (this.tableDate[index].check) {
				this.active_table.push(this.tableDate[index])
			} else {
				this.tableDate.forEach((item, index) => {
					this.active_table.forEach((el, i) => {
						if (!this.tableDate[index].check && this.active_table[i].id == this.tableDate[index].id) {
							this.active_table.splice(i, 1)
						}
					})
				})
			}
		},
		get_list() {
			this.fullscreenLoading = true
			let date_form = {}
			if (this.form.title != '') {
				date_form.title = this.form.title
			}
			date_form.page = this.page
			date_form.limit = 10
			this.curlGet('/api/users/guanggao_invoice/order', date_form).then((res) => {
				if (res.data.code) {
					this.tableDate = res.data.data.list
					this.tableDate.forEach((item, index) => {
						this.$set(item, 'check', false)
						if (this.active_table.length != 0) {
							this.active_table.forEach((el, i) => {
								if (this.tableDate[index].id == this.active_table[i].id) {
									this.tableDate[index].check = true
								}
							})
						}
					})
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.fullscreenLoading = false
				}
			})

		},
		InvoiceLs(val) {
			let resultArr = this.Invoiceform.filter((item) => {
				return item.id === val
			})
			this.form.type = resultArr[0]
		},
		// 提交
		submitFn() {
			if (this.form.type == '') {
				return this.$message({
					type: 'warning',
					message: '请选择开票标题',
				})
			}
			let order_ids = ''
			this.tableDate.forEach((item, index) => {
				if (item.check) {
					order_ids += `${item.id},`
				}
			})
			if (order_ids == '') {
				return this.$message({
					type: 'warning',
					message: '请选择需要开票的订单',
				})
			}
			this.fullscreenLoading = true
			this.curlPost('/api/users/guanggao_invoice/save', {
				style: this.form.radio.id,
				info_id: this.form.type.id,
				type: this.form.invoiceContent,
				order_ids: order_ids,
			}).then((res) => {
				console.log(res)
				if (res.data.code) {
					this.$message({
						type: 'success',
						message: '发票申请成功,等待审核',
					})
					this.get_list()
					// this.$store.state.ApplyForInvoiceTitle = '我的开票资料'
					this.$router.push('/placard_user/placard_invoice')
					this.$store.state.menu_active = '/user/placard_invoice'
				} else {
					this.$message({
						type: 'warning',
						message: res.data.msg,
					})
				}
			})
			this.fullscreenLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/ApplyForInvoice';
</style>